import React, { Component } from 'react';

//Stylesheets
import './../../stylesheets/Normalize.css';

import PopularDestination from './PopularDestination';
import HighlightedPackage from './HighlightedPackage';
import Navigation from './../navigation/Navigation';
import FitPackages from './FITPackages';  
import Footer from './../navigation/Footer';

export default class Home extends Component {
   render() {
      return(
         <div className="home-wrapper">
            <Navigation/>
            <PopularDestination/>
            <HighlightedPackage/>
            <FitPackages/>
            <Footer/>
         </div>
      )
   }
}