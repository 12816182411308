import React, { Component } from 'react';

//Stylesheets
import './../../stylesheets/Normalize.css';
import './../../stylesheets/TourPackage.css';

export default class TourPackageHeader extends Component {
   render() {
      return(
         <div className="tour-package-header">
         </div>
      )
   }
}