import React, { Component } from 'react';

export default class Facebook extends Component {
   render() {
      return (
         <svg width="25" height="25" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0)">
            <path d="M34.6105 0H5.39106C2.41426 0 0.000976562 2.41318 0.000976562 5.39008V34.6097C0.000976562 37.5866 2.41416 39.9998 5.39106 39.9998H19.802L19.8265 25.706H16.113C15.6304 25.706 15.2387 25.3158 15.2368 24.8332L15.219 20.2257C15.2172 19.7405 15.61 19.3462 16.0953 19.3462H19.8021V14.8942C19.8021 9.72768 22.9574 6.91449 27.5663 6.91449H31.3482C31.8321 6.91449 32.2244 7.30677 32.2244 7.79074V11.6758C32.2244 12.1596 31.8323 12.5517 31.3487 12.552L29.0278 12.5531C26.5213 12.5531 26.036 13.7441 26.036 15.492V19.3463H31.5435C32.0683 19.3463 32.4754 19.8045 32.4136 20.3256L31.8675 24.9331C31.8152 25.374 31.4413 25.7062 30.9974 25.7062H26.0606L26.036 40H34.6107C37.5875 40 40.0007 37.5868 40.0007 34.61V5.39008C40.0006 2.41318 37.5874 0 34.6105 0Z" fill="#475993"/>
            </g>
            <defs>
            <clipPath id="clip0">
            <rect width="40" height="40" fill="white"/>
            </clipPath>
            </defs>
         </svg>
      )
   }
}
