import React, { Component } from 'react';

//Stylesheets
import './../../stylesheets/Normalize.css';
import './../../stylesheets/Services.css';

import ServicesHeader from './ServicesHeader';
import ServicesContent from './ServicesContent';

export default class Services extends Component {
   render() {
      return (
         <div className="services-wrapper">
            <ServicesHeader/>
            <ServicesContent/>
         </div>
      )
   }
}