import React from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';

//Import Components
import Home from './components/home/Home';
import Destination from './components/destination/Destination';
import TourPackage from './components/tour-package/TourPackage';
import PackageList from './components/tour-package/PackageList';
// import TourPackageBookingForm from './components/tour-package/TourPackageBookingForm';
import Services from './components/services/Services';
import Contact from './components/contact/Contact';

function App() {
  return (
    <Router>
      <div className="main">
        <Switch>
          <Redirect exact from="/" to="/home"/>
          <Route path="/home" component={Home}/>
          <Route path="/destination" component={Destination}/>
          <Route exact path="/:id" component={PackageList}/>
          <Route path="/services" component={Services}/>
          <Route path="/contact" component={Contact}/>
          <Route path="/package" component={TourPackage}/>
        </Switch>
      </div>
    </Router>
  );
}

export default App;
