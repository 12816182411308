import React, { Component } from 'react';
import firebase from '../../firebase';

import Navigation from '../navigation/Navigation';
import './../../stylesheets/PackageList.css';

/* Import Stylesheets */
import './../../stylesheets/Destination.css';

import Day from '../svg/day';
import Night from '../svg/night';
import Price from '../svg/price';
import Date from '../svg/date';

export default class PackageList extends Component {
   constructor(props) {
      super(props);

      this.state = {
         packages: [],
      };
   }


   componentDidMount() {
      firebase
         .firestore()
         .collection(this.props.match.params.id)
         .get()
         .then(querySnapshot => {
            const packages = [];

            querySnapshot.forEach(function(doc) {
               packages.push({
                  code: doc.id,
                  title: doc.data().Title,
                  day: doc.data().Day,
                  night: doc.data().Night,
                  price: doc.data().Price,
                  start_date: doc.data().Start_Date,
                  end_date: doc.data().End_Date,
                  image: doc.data().Image,
               });
            });

            this.setState({ packages });
         }).catch(function(error) {
            console.log("Error retrieving document: ", error);
         });
   }
   
   render() {
      return(
         <React.Fragment>
            <Navigation/>
            <div className="package-list-package-wrapper">
               {this.state.packages.map((p, i) => {
                  return (
                     <div key={i} onClick={() => this.openPackage(p.code)} className="package-wrapper">
                        <div className={[p.image, "package-image"].join(" ")}>
                           <div className="package-title">{p.title}</div>

                           <div className="tour-package-content-info">
                              <div className="tour-package-icon">
                                 <Day></Day>
                                 <p>{p.day}</p>
                              </div>
                              <div className="tour-package-icon">
                                 <Night></Night>
                                 <p>{p.night}</p>
                              </div>
                              <div className="tour-package-icon">
                                 <Price></Price>
                                 <p>RM {p.price}</p>
                              </div>
                              <div className="tour-package-icon">
                                 <Date></Date>
                                 <p>{p.start_date} - {p.end_date}</p>
                              </div>
                           </div>
                        </div>
                     </div>
                  );
               })}
            </div>
         </React.Fragment>
      )
   }

   openPackage(packageId) {
      var countryStorage = firebase.storage().ref();
      var pdfRef = countryStorage.child(['PDF/', this.props.match.params.id.toLowerCase(), "/", `${packageId}.pdf`].join(""));

      pdfRef.getDownloadURL().then(function(url) {
         return (
            window.open(url, '_blank')
         )
      }).catch(function(error) {
         switch (error.code) {
            case 'storage/object-not-found':
              // File doesn't exist
              console.log("Object not found");
              break;
        
            case 'storage/unauthorized':
              // User doesn't have permission to access the object
              console.log("Not authorized");
              break;
        
            case 'storage/canceled':
              // User canceled the upload
              console.log("Cancelled");
              break;

            case 'storage/unknown':
              // Unknown error occurred, inspect the server response
              console.log("Server Error");
              break;

            default:
               console.log("Unknown Error");
               break;
          }
      })
   }

}