import * as firebase from 'firebase';
import 'firebase/firestore';

var firebaseConfig = {
   apiKey: "AIzaSyANcb6ZAcghebuPeXBwhMIhERmRcdVVSzA",
   authDomain: "gsitravel-pj.firebaseapp.com",
   databaseURL: "https://gsitravel-pj.firebaseio.com",
   projectId: "gsitravel-pj",
   storageBucket: "gsitravel-pj.appspot.com",
   messagingSenderId: "694830524599",
   appId: "1:694830524599:web:b9df88b5507aa2bf21c532",
   measurementId: "G-X9RV1Q8VMH"
 };
 // Initialize Firebase
 firebase.initializeApp(firebaseConfig);
 firebase.analytics();

 export default firebase;