import React, { Component } from 'react';

export default class ContactContent extends Component {
   render() {
      return (
         <div className="contact-content">
            <div className="contact-us">
               <h3 className="underline">Contact Us</h3>

               <div className="address">
                  <p>No. Lesen: KPL/LN: 4386</p>
                  <p>F111, 1st Floor Centre Point Neighbourhood</p>
                  <p>3, Lebuh Bandar Utama</p>
                  <p>47800, Petaling Jaya</p>
               </div>

               <div className="phone-number">
                  <p>Office Number: +603 - 7728 8616</p>
                  <p>Fax Number: +603 - 7728 9616</p>
                  <p>What's App Number: +6012 - 538 3220</p>
               </div>

               <div className="email-address">
                  <p>gsitravelbu@gmail.com </p>
                  <p>info@gsitravel.com.my</p>
               </div>
            </div>

            <div className="office-hours">
               <h3 className="underline">Office Hours</h3>

               <div className="office-hours-time">
                  <p>Monday - Friday</p>
                  <p>9:00am - 5:30pm</p>
                  <p>Saturday</p>
                  <p>9:00am - 1:00pm</p>
                  <p>We are closed on sundays and public holidays</p>
               </div>
            </div>
         </div>
      )
   }
}