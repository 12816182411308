import React, { Component } from 'react';
import { Link } from "react-router-dom";

import Heart from './../svg/heart';
import WhatsApp from './../svg/whatsapp';
import FaceBook from './../svg/facebook';
import Instagram from './../svg/instagram';

import './../../stylesheets/Normalize.css';
import './../../stylesheets/Footer.css';

export default class Navigation extends Component {
   render() {
      return(
         <React.Fragment>
            <div className="footer-wrapper">
               <p className="footer-copyright">© 2020 GSI Travel Sdn. Bhd. All Rights Reserved.</p>
               <p className="footer-made">Designed and Built with <Heart/> in Petaling Jaya</p>
               
               <div className="footer-social">
                  <Link to="//wa.me/60125383220" target="_blank" rel='noopener noreferrer'>
                     <WhatsApp/>
                  </Link>
                  <Link to="//www.facebook.com/itravelwithgsi/" target="_blank" rel='noopener noreferrer'>
                     <FaceBook/>
                  </Link>
                  <Link to="//www.instagram.com/itravelwithgsi/" target="_blank" rel='noopener noreferrer'>
                     <Instagram/>
                  </Link>
               </div>
            </div>
         </React.Fragment>
      )
   }
}