import React, { Component } from 'react';
import { Link } from "react-router-dom";

import './../../stylesheets/Normalize.css';
import './../../stylesheets/PopularDestination.css';

export default class PopularDestination extends Component {
   state = {
      populardestinations: [
         {
            id: 1,
            title: 'China',
            image: 'China-Popular'
         },
         {
            id: 2,
            title: 'Thailand',
            image: 'Thailand-Popular'
         },
         {
            id: 3,
            title: 'South Korea',
            image: 'South-Korea-Popular'
         },
         {
            id: 4,
            title: 'Japan',
            image: 'Japan-Popular'
         },
         {
            id: 5,
            title: 'Hong Kong',
            image: 'Hong-Kong-Popular'
         },
         {
            id: 6,
            title: 'America',
            image: 'America-Popular'
         },
         {
            id: 8,
            title: 'Philippines',
            image: 'Philippines-Popular'
         },
         {
            id: 8,
            title: 'Australia',
            image: 'Australia-Popular'
         }
      ]
   }

   render() {
      return (
         <React.Fragment>
         <div className="component-title">
            <h3>Popular Destinations</h3>
            <p>Explore our top selling travel destinations</p>
         </div>
         <div className="popular-destination-wrapper">
            <div className="popular-destination-scroll">
               {this.state.populardestinations.map((destination, i) => (
                  <React.Fragment key={i}>
                     <div className="destination-wrapper">
                        <Link to={{pathname:["/", destination.title.toLowerCase().replace(/\s|-/g, '')].join(""), state: destination.title.toLowerCase().replace(/\s/g, '')}} >
                           <div className={[destination.image, "destination-image"].join(" ")} >
                           </div>
                        </Link>
                        <div className="destination-title">{destination.title}</div>
                     </div>
                  </React.Fragment>
               ))}
            </div>
         </div>
         </React.Fragment>
      )
   }
}
