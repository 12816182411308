import React, { Component } from 'react';

//Import SVGs
import CalendarClock from './../svg/calendarclock'
import Flight from './../svg/flight';
import PriceRed from './../svg/pricered';

//Stylesheets
import './../../stylesheets/Normalize.css';
import './../../stylesheets/TourPackage.css';

export default class TourPackageContent extends Component {
   
   render() {
      return(
         <div className="tour-package-content-wrapper">
            <div className="tour-package-content-first-wrapper">
               <div className="tour-package-content-first">
                  <div className="tour-package-flight">
                     <Flight/>
                     <p><span className="bold">Guangzhou,</span> China</p>
                  </div>
                  <div className="tour-package-dates">
                     <CalendarClock/>
                     <div className="tour-package-dates-scroll">
                        <div className="tour-package-dates-options">
                           <p>March 1st, 2019</p>
                        </div>
                        <div className="tour-package-dates-options">
                           <p>September 30st, 2019</p>
                        </div>
                        <div className="tour-package-dates-options">
                           <p>January 30st, 2019</p>
                        </div>
                        <div className="tour-package-dates-options">
                           <p>January 32st, 2019</p>
                        </div>
                     </div>
                  </div>
               </div>

               <div className="tour-package-content-second">
                  <div className="tour-package-content-second-inner">
                     <div className="tour-package-content-second-row1">
                        <div className="tour-package-price">
                           <PriceRed/>
                           <p>RM 8,999</p>
                        </div>
                        <div className="tour-package-book-button">
                           <p>Book Now</p>
                        </div>
                     </div>
                     
                     <div className="tour-package-itinerary">
                        <div className="tour-package-itinerary-title">
                           <h1>Tour Itinerary</h1>
                        </div>
                        <div className="tour-package-day-info">
                           <div className="tour-package-day-title-container">
                              <div className="tour-package-day">
                                 <h3>1</h3>
                              </div>
                              <div className="tour-package-day-title">
                                 <h3>Day 1: Depart Kuala Lumpur</h3>
                              </div>
                           </div>

                           <div className="tour-package-day-list">
                              <ul>
                                 <li>Leave Kuala Lumpur</li>
                                 <li>Lol</li>
                                 <li>What?</li>
                              </ul>
                           </div>
                        </div>

                        <div className="tour-package-day-info">
                           <div className="tour-package-day-title-container">
                              <div className="tour-package-day">
                                 <h3>2</h3>
                              </div>
                              <div className="tour-package-day-title">
                                 <h3>Day 2: Do something @ Destination</h3>
                              </div>
                           </div>

                           <div className="tour-package-day-list">
                              <ul>
                                 <li>1 Hour Liberty Cruise (Ticket Included)</li>
                                 <li>Independence National Historical Park (Photo Stop)</li>
                                 <li>What?</li>
                              </ul>
                           </div>
                        </div>

                        <div className="tour-package-day-info">
                           <div className="tour-package-day-title-container">
                              <div className="tour-package-day">
                                 <h3>3</h3>
                              </div>
                              <div className="tour-package-day-title">
                                 <h3>Day 3: Final Day?</h3>
                              </div>
                           </div>
                        </div>

                     </div>
                  </div>
               </div>
            </div>
         </div>
      )
   }
}