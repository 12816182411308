import React, { Component } from 'react';

export default class PDF extends Component {
   render() {
      return (
         <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10 0C8.625 0 7.5 1.125 7.5 2.5V37.5C7.5 38.875 8.625 40 10 40H35C36.375 40 37.5 38.875 37.5 37.5V10L27.5 0H10Z" fill="#E2E5E7"/>
            <path d="M30 10H37.5L27.5 0V7.5C27.5 8.875 28.625 10 30 10Z" fill="#B0B7BD"/>
            <path d="M37.5 17.5L30 10H37.5V17.5Z" fill="#CAD1D8"/>
            <path d="M32.5 32.5C32.5 33.1875 31.9375 33.75 31.25 33.75H3.75C3.0625 33.75 2.5 33.1875 2.5 32.5V20C2.5 19.3125 3.0625 18.75 3.75 18.75H31.25C31.9375 18.75 32.5 19.3125 32.5 20V32.5Z" fill="#F15642"/>
            <path d="M7.94873 23.6837C7.94873 23.3537 8.20873 22.9937 8.62748 22.9937H10.9362C12.2362 22.9937 13.4062 23.8637 13.4062 25.5311C13.4062 27.1111 12.2362 27.9911 10.9362 27.9911H9.26748V29.3111C9.26748 29.7511 8.98748 29.9999 8.62748 29.9999C8.29748 29.9999 7.94873 29.7511 7.94873 29.3111V23.6837ZM9.26748 24.2524V26.7424H10.9362C11.6062 26.7424 12.1362 26.1511 12.1362 25.5311C12.1362 24.8324 11.6062 24.2524 10.9362 24.2524H9.26748Z" fill="white"/>
            <path d="M15.3637 30.0002C15.0337 30.0002 14.6737 29.8202 14.6737 29.3815V23.704C14.6737 23.3452 15.0337 23.084 15.3637 23.084H17.6525C22.22 23.084 22.12 30.0002 17.7425 30.0002H15.3637ZM15.9937 24.304V28.7815H17.6525C20.3512 28.7815 20.4712 24.304 17.6525 24.304H15.9937Z" fill="white"/>
            <path d="M23.74 24.3838V25.9725H26.2887C26.6487 25.9725 27.0087 26.3325 27.0087 26.6813C27.0087 27.0113 26.6487 27.2813 26.2887 27.2813H23.74V29.38C23.74 29.73 23.4912 29.9988 23.1412 29.9988C22.7012 29.9988 22.4325 29.73 22.4325 29.38V23.7025C22.4325 23.3438 22.7025 23.0825 23.1412 23.0825H26.65C27.09 23.0825 27.35 23.3438 27.35 23.7025C27.35 24.0225 27.09 24.3825 26.65 24.3825H23.74V24.3838Z" fill="white"/>
            <path d="M31.25 33.75H7.5V35H31.25C31.9375 35 32.5 34.4375 32.5 33.75V32.5C32.5 33.1875 31.9375 33.75 31.25 33.75Z" fill="#CAD1D8"/>
         </svg>
      )
   }
}
