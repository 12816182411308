import React, { Component } from 'react';

export default class CalendarClock extends Component {
   render() {
      return(
         <svg width="25" height="25" viewBox="0 0 23 24" fill="#FFF" xmlns="http://www.w3.org/2000/svg">
            <rect width="25" height="25" fill="none"/>
            <path d="M14.375 13.0001H15.8125V15.8201L18.1508 17.2301L17.4321 18.5301L14.375 16.6901V13.0001ZM18.2083 8.00006H4.79167V19.0001H9.26708C8.855 18.0901 8.625 17.0701 8.625 16.0001C8.625 14.1435 9.33177 12.3631 10.5898 11.0503C11.8479 9.73756 13.5542 9.00006 15.3333 9.00006C16.3587 9.00006 17.3362 9.24006 18.2083 9.67006V8.00006ZM4.79167 21.0001C3.72792 21.0001 2.875 20.1001 2.875 19.0001V5.00006C2.875 3.89006 3.72792 3.00006 4.79167 3.00006H5.75V1.00006H7.66667V3.00006H15.3333V1.00006H17.25V3.00006H18.2083C18.7167 3.00006 19.2042 3.21077 19.5636 3.58585C19.9231 3.96092 20.125 4.46963 20.125 5.00006V11.1001C21.3133 12.3601 22.0417 14.0901 22.0417 16.0001C22.0417 17.8566 21.3349 19.6371 20.0768 20.9498C18.8188 22.2626 17.1125 23.0001 15.3333 23.0001C13.5029 23.0001 11.845 22.2401 10.6375 21.0001H4.79167ZM15.3333 11.1501C14.1006 11.1501 12.9184 11.661 12.0468 12.5706C11.1751 13.4801 10.6854 14.7138 10.6854 16.0001C10.6854 18.6801 12.765 20.8501 15.3333 20.8501C15.9437 20.8501 16.5481 20.7246 17.112 20.4809C17.6759 20.2371 18.1883 19.8799 18.6199 19.4295C19.0515 18.9792 19.3939 18.4445 19.6274 17.8561C19.861 17.2676 19.9813 16.637 19.9813 16.0001C19.9813 13.3201 17.9017 11.1501 15.3333 11.1501Z" fill="white"/>
         </svg>
      )
   }
}