import React, { Component } from 'react';

export default class Instagram extends Component {
   render() {
      return (
         <svg width="25" height="25" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M28.0838 0H11.9162C5.34559 0 0 5.34559 0 11.9162V28.0838C0 34.6544 5.34559 40 11.9162 40H28.0838C34.6544 40 40 34.6544 40 28.0838V11.9162C39.9999 5.34559 34.6543 0 28.0838 0ZM35.976 28.0838C35.976 32.4425 32.4425 35.976 28.0838 35.976H11.9162C7.5575 35.976 4.024 32.4425 4.024 28.0838V11.9162C4.024 7.55743 7.5575 4.024 11.9162 4.024H28.0838C32.4425 4.024 35.976 7.55743 35.976 11.9162V28.0838Z" fill="url(#paint0_linear)"/>
            <path d="M20 9.65479C14.2955 9.65479 9.65457 14.2957 9.65457 20.0001C9.65457 25.7045 14.2955 30.3456 20 30.3456C25.7045 30.3456 30.3454 25.7046 30.3454 20.0001C30.3454 14.2957 25.7045 9.65479 20 9.65479ZM20 26.3216C16.5087 26.3216 13.6786 23.4915 13.6786 20.0002C13.6786 16.509 16.5088 13.6788 20 13.6788C23.4912 13.6788 26.3214 16.509 26.3214 20.0002C26.3214 23.4914 23.4912 26.3216 20 26.3216Z" fill="url(#paint1_linear)"/>
            <path d="M30.3654 12.2114C31.7346 12.2114 32.8444 11.1015 32.8444 9.73239C32.8444 8.36329 31.7346 7.25342 30.3654 7.25342C28.9963 7.25342 27.8865 8.36329 27.8865 9.73239C27.8865 11.1015 28.9963 12.2114 30.3654 12.2114Z" fill="url(#paint2_linear)"/>
            <defs>
            <linearGradient id="paint0_linear" x1="20" y1="39.8836" x2="20" y2="0.310687" gradientUnits="userSpaceOnUse">
            <stop stopColor="#E09B3D"/>
            <stop offset="0.3" stopColor="#C74C4D"/>
            <stop offset="0.6" stopColor="#C21975"/>
            <stop offset="1" stopColor="#7024C4"/>
            </linearGradient>
            <linearGradient id="paint1_linear" x1="20" y1="39.8838" x2="20" y2="0.310899" gradientUnits="userSpaceOnUse">
            <stop stopColor="#E09B3D"/>
            <stop offset="0.3" stopColor="#C74C4D"/>
            <stop offset="0.6" stopColor="#C21975"/>
            <stop offset="1" stopColor="#7024C4"/>
            </linearGradient>
            <linearGradient id="paint2_linear" x1="30.3654" y1="39.8837" x2="30.3654" y2="0.310837" gradientUnits="userSpaceOnUse">
            <stop stopColor="#E09B3D"/>
            <stop offset="0.3" stopColor="#C74C4D"/>
            <stop offset="0.6" stopColor="#C21975"/>
            <stop offset="1" stopColor="#7024C4"/>
            </linearGradient>
            </defs>
         </svg>
      )
   }
}
