import React, { Component } from 'react';

export default class Heart extends Component {
   render() {
      return (
         <svg width="25" height="25" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M20 37.3915C19.6977 37.3915 19.3954 37.2867 19.1513 37.0774C17.9475 36.0461 16.7807 35.058 15.6568 34.1069C6.56758 26.4128 0 20.8536 0 13.7158C0 7.38389 4.48586 2.60889 10.4348 2.60889C15.4959 2.60889 18.4909 6.14022 20 8.71029C21.5091 6.14022 24.5041 2.60889 29.5652 2.60889C35.5141 2.60889 40 7.38389 40 13.7158C40 20.8536 33.4324 26.4128 24.3431 34.1068C23.2192 35.0579 22.0524 36.0459 20.8487 37.0773C20.6046 37.2866 20.3023 37.3915 20 37.3915Z" fill="#E6563A"/>
            <path d="M20.8488 37.0773C22.0525 36.046 23.2193 35.058 24.3432 34.1069C33.4324 26.4128 40 20.8536 40 13.7158C40 7.38389 35.5141 2.60889 29.5652 2.60889C24.5041 2.60889 21.5091 6.14022 20 8.71029V37.3915C20.3023 37.3915 20.6046 37.2866 20.8488 37.0773Z" fill="#D9472B"/>
         </svg>
      )
   }
}
