import React, { Component } from 'react';

//Stylesheets
import './../../stylesheets/Normalize.css';
import './../../stylesheets/Contact.css';

import ContactHeader from './ContactHeader';
import ContactContent from './ContactContent';

export default class Contact extends Component {
   render() {
      return(
         <div className="contact-wrapper">
            <ContactHeader/>
            <ContactContent/>
         </div>
      )
   }
}