import React, { Component } from 'react';
import { Link } from "react-router-dom";

import Logo from './../svg/logo';

import './../../stylesheets/Normalize.css';
import './../../stylesheets/Navigation.css';

export default class Navigation extends Component {
   constructor(props) {
      super(props);
      this.toggleMenu = this.toggleMenu.bind(this);
      this.state = {
         isClick: false
      };
   }

   toggleMenu() {
      const currentState = this.state.isClick;
      this.setState({ isClick: !currentState });
   }

   render() {
      return(
         <div className="navigation-bar">
            <div className="logo">
               <Link to="/home">
                  <Logo/>
               </Link>
            </div>

            <div className="burger" onClick={this.toggleMenu}>
               <div className="line1"></div>
               <div className="line2"></div>
               <div className="line3"></div>
            </div>
            
            <ul className={[(this.state.isClick ? 'nav-active' : null), 'main-navigation'].join(" ")}>
               <li className="nav-link">
                  <Link to="/home">
                     Home
                  </Link>
               </li>
               <li className="nav-link">
                  <Link to="/destination">
                     Destination
                  </Link>
               </li>
               {/* <li className="nav-link">
                  <Link to="/services">
                     Services
                  </Link>
               </li>
               <li className="nav-link">
                  <Link to="/contact">
                     Contact
                  </Link>
               </li> */}
            </ul>

            
         </div>
      )
   }
}