import React, { Component } from 'react';

export default class Flight extends Component {
   render() {
      return(
         <svg width="25" height="25" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2.91667 22.1666H25.0833V24.5H2.91667V22.1666ZM25.7483 11.2466C25.5033 10.3133 24.535 9.75331 23.6017 10.01L17.4067 11.6666L9.35667 4.16498L7.105 4.75998L11.935 13.125L6.13667 14.6766L3.83833 12.88L2.14667 13.335L4.27 17.0216L5.16833 18.5733L7.035 18.0716L13.23 16.415L18.305 15.0616L24.5 13.405C25.445 13.1366 25.9933 12.18 25.7483 11.2466Z" fill="white"/>
         </svg>

      )
   }
}