import React, { Component } from 'react';

//Stylesheets
import './../../stylesheets/Normalize.css';
import './../../stylesheets/TourPackage.css';

import TourPackageHeader from './TourPackageHeader';
import TourPackageContent from './TourPackageContent';

export default class TourPackage extends Component {
   render() {
      return (
         <div className="tour-package">
            <TourPackageHeader destination={"Guangzhou"}/>
            <TourPackageContent/>
         </div>
      )
   }
}