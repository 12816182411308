import React, { Component } from 'react';

import PDF from './../svg/pdf';

export default class ServicesContent extends Component {
   render() {
      return (
         <div className="services-content">
            <div className="visa-application-info">
               <div className="visa-application-title">
                  <h3 className="underline">Visa Services</h3>
               </div>

            
               <div className="visa-application-info-title">
                  <div className="visa-application-country-title">
                     <p>Country</p>
                  </div>
                  <div className="visa-application-processing-title">
                     <p>Processing Time</p>
                  </div>
                  <div className="visa-application-price-title">
                     <p>Application Fee</p>
                  </div>
               </div>

               <div className="visa-application-info-item">
                  <div className="visa-application-country">
                     <p>China</p>
                  </div>
                  <div className="visa-application-processing">
                     <p>3 Days</p>
                  </div>
                  <div className="visa-application-price">
                     <p>MYR 200</p>
                  </div>
               </div>

               <div className="visa-application-info-item">
                  <div className="visa-application-country">
                     <p>Australia</p>
                  </div>
                  <div className="visa-application-processing">
                     <p>2 Days</p>
                  </div>
                  <div className="visa-application-price">
                     <p>MYR 60</p>
                  </div>
               </div>

               <div className="visa-application-info-item">
                  <div className="visa-application-country">
                     <p>Myanmar</p>
                  </div>
                  <div className="visa-application-processing">
                     <p>5 Days</p>
                  </div>
                  <div className="visa-application-price">
                     <p>MYR 100</p>
                  </div>
               </div>

               <div className="visa-application-info-item">
                  <div className="visa-application-country">
                     <p>America</p>
                  </div>
                  <div className="visa-application-processing">
                     <p>7 Days</p>
                  </div>
                  <div className="visa-application-price">
                     <p>MYR 210</p>
                  </div>
               </div>
            </div>

            <div className="travel-insurance-info">
               <div className="travel-insurance-title">
                  <h3 className="underline">Travel Insurance</h3>
               </div>
               <div className="travel-insurance-desc">
                  <p>GSI Travel highly recommends our customer to stay safe with
                     our recommended travel insurance package. Travel with peace
                     at mind to your next holiday destination with AIG or AXA
                     insurance.
                  </p>
               </div>
               <div className="travel-insurance-pdf">
                  <div className="travel-insurance-pdf-item">
                     <PDF/>
                     <p>AIG Insurance</p>
                  </div>
                  <div className="travel-insurance-pdf-item">
                     <PDF/>
                     <p>AXA Insurance</p>
                  </div>
               </div>
            </div>
         </div>
      )
   }
}