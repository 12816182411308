import React, { Component } from 'react';
import { Link } from "react-router-dom";

import Navigation from './../navigation/Navigation';
import data from './../../data/tour-packages';

/* Import Stylesheets */
import './../../stylesheets/Destination.css';

export default class Destination extends Component {
   render() {
      return(
         <React.Fragment>
            <Navigation/>
            <div className="continent-wrapper">
               { data.Destination.map((destination, i) => {
                  return (
                     <div key={i}>
                        <p className="continent-title">{destination.List}</p>
                        <div className="destination-list">
                           <div className="country-image-wrapper">
                              <div className="country-image-scroll">
                                 {destination.Area.map(function (c, j) {
                                    return (
                                       <div className="country-wrapper">
                                          <Link to={{pathname:["/", c.Country.toLowerCase().replace(/\s|-/g, '')].join(""), state: c.Country.toLowerCase().replace(/\s|-/g, '')}}>
                                             <div key={j} className={[c.Country, "country-image"].join(" ")}>
                                                <p className="country-title">{c.Country.replace(/-/g, ' ')}</p>
                                             </div> 
                                          </Link>
                                       </div>
                                    )
                                 })}
                              </div>
                           </div>
                        </div>
                     </div>
                  );
               })}
            </div>
         </React.Fragment>
      )
   }
}