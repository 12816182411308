import React, { Component } from 'react';

export default class WhatsApp extends Component {
   render() {
      return (
         <svg width="25" height="25" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0)">
            <path d="M19.0301 0.0231325C8.46617 0.526128 0.129727 9.32386 0.161774 19.8998C0.171532 23.1209 0.949175 26.1613 2.32098 28.8477L0.214771 39.0715C0.100837 39.6245 0.599623 40.1087 1.14902 39.9786L11.1671 37.6052C13.7409 38.8872 16.6319 39.6278 19.6914 39.6744C30.4888 39.8394 39.4919 31.266 39.8285 20.4726C40.1892 8.90285 30.6404 -0.529799 19.0301 0.0231325ZM30.9839 30.8223C28.05 33.7562 24.1491 35.3719 20.0001 35.3719C17.5706 35.3719 15.2447 34.8268 13.0866 33.7519L11.6914 33.0569L5.54932 34.5121L6.8422 28.2359L6.15486 26.89C5.03455 24.6959 4.46651 22.3234 4.46651 19.8384C4.46651 15.6892 6.08225 11.7884 9.01614 8.85444C11.9238 5.94677 15.8883 4.30472 20.0003 4.30472C24.1494 4.30481 28.0501 5.92056 30.984 8.85435C33.9179 11.7882 35.5336 15.689 35.5337 19.8381C35.5336 23.9502 33.8915 27.9147 30.9839 30.8223Z" fill="#7AD06D"/>
            <path d="M29.6279 24.1407L25.7853 23.0373C25.2801 22.8923 24.736 23.0356 24.3679 23.4107L23.4282 24.3681C23.032 24.7718 22.4307 24.9015 21.9063 24.6893C20.0885 23.9537 16.2647 20.5538 15.2882 18.8532C15.0064 18.3626 15.053 17.7493 15.3989 17.3017L16.2193 16.2404C16.5408 15.8245 16.6086 15.266 16.396 14.7853L14.7793 11.1288C14.3921 10.253 13.2729 9.99806 12.5418 10.6164C11.4693 11.5235 10.1968 12.9019 10.0421 14.429C9.76937 17.1213 10.924 20.5152 15.2904 24.5905C20.3347 29.2985 24.3741 29.9205 27.0042 29.2834C28.496 28.9221 29.6881 27.4736 30.4405 26.2875C30.9534 25.4789 30.5484 24.405 29.6279 24.1407Z" fill="#7AD06D"/>
            </g>
            <defs>
            </defs>
         </svg>
      )
   }
}
