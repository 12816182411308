import React, { Component } from 'react';

export default class ContactHeader extends Component {
   render() {
      return(
         <div className="contact-header">
            
         </div>
      )
   }
}