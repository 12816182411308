import React, { Component } from 'react';

//Stylesheets
import './../../stylesheets/Normalize.css';
import './../../stylesheets/FITPackages.css';

import FIT from './../svg/fit';

export default class FITPackages extends Component {
   render() {
      return(
         <React.Fragment>
            <div className="component-title">
               <h3>For Individual Travellers</h3>
               <p>Whether you’re travelling in a group or individually, we have custom made tour package just for you.</p>
            </div>

            <div className="fit-wrapper">
               <div className="fit-packages">
                  <div className="fit-title"><FIT/></div>
                  <button onClick={this.openFIT}>Explore Packages ></button>
               </div>
            </div>
         </React.Fragment>
      )
   }

   openFIT() {
      window.open("https://drive.google.com/file/d/1hjKacMTbj1-UICmYDcf7guNl5mE_RK8m/view", '_blank');
   }
}